import Vue from 'vue'
import VueRouter from 'vue-router'
import {canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import store from '@/store'

Vue.use(VueRouter)

const routesList = [
    {
        path: '/cart/addToCart',
        name: 'Automatyczne dodawanie do koszyka',
        component: () => import('@/views/AddToCart.vue'),
        meta: {
            openForGuest: true,
            pageTitle: 'Automatyczne dodawanie do koszyka',
            breadcrumb: [],
        }
    },
    {
        path: '/email/verify',
        name: 'email verification',
        component: () => import('@/views/email/verify.vue'),
        meta: {
            openForGuest: false,
            pageTitle: 'Weryfikacja adresu e-mail',
            breadcrumb: [
                {
                    text: 'Weryfikacja adresu e-mail',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/email/confirmed',
        name: 'email verified',
        component: () => import('@/views/email/confirmed.vue'),
        meta: {
            openForGuest: false,
            pageTitle: 'Weryfikacja adresu e-mail',
            breadcrumb: [
                {
                    text: 'Weryfikacja adresu e-mail',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Panel główny',
            breadcrumb: [
                {
                    text: 'Panel główny',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/individual',
        name: 'individual',
        component: () => import('@/views/individual/Individual.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Materiały indywidualne',
            breadcrumb: [
                {
                    text: 'Materiały indywidualne',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/chemmystery',
        name: 'chemmystery',
        component: () => import('@/views/chemmystery/ChemMystery.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'ChemMystery',
            breadcrumb: [
                {
                    text: 'ChemMystery',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/chemmystery/questions',
        name: 'chemmystery-questions',
        component: () => import('@/views/chemmystery/ChemMysteryQuestions.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'ChemMystery - Subskrypcja',
            breadcrumb: [
                {
                    text: 'ChemMystery Subskrypcja',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/moje-kursy',
        name: 'my-courses',
        component: () => import('@/views/MyCourses.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Moje materiały',
            breadcrumb: [
                {
                    text: 'Moje materiały',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/moje-kursy/:slug',
        name: 'single-course',
        component: () => import('@/views/Course.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            layout: 'custom',
            pageTitle: 'Kurs',
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/materialy-dodatkowe/:slug',
        name: 'single-course',
        component: () => import('@/views/AdditionalMaterialsCourse.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            layout: 'custom',
            pageTitle: 'Materiały dodatkowe',
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/sklep/:slug',
        name: 'shop-item-details',
        component: () => import('@/views/shop/ItemDetails.vue'),
        meta: {
            pageTitle: 'Produkt',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienie',
        name: 'checkout',
        component: () => import('@/views/shop/Checkout.vue'),
        meta: {
            pageTitle: 'Koszyk',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienia',
        name: 'orders',
        component: () => import('@/views/MyOrders.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Moje zamówienia',
            breadcrumb: [
                {
                    text: 'Moje zamówienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienia/:orderId',
        name: 'orders',
        component: () => import('@/views/MyOrder.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Moje zamówienia',
            breadcrumb: [
                {
                    text: 'Moje zamówienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/moje-rabaty',
        name: 'discounts',
        component: () => import('@/views/Discounts.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Moje rabaty',
            breadcrumb: [
                {
                    text: 'Moje rabaty',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/ustawienia',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Ustawienia',
            breadcrumb: [
                {
                    text: 'Ustawienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/order/:orderId/success',
        name: 'ty',
        component: () => import('@/views/OrderThankYou.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'TAK! Udało się! 💙',
            breadcrumb: [
                {
                    text: 'Potwierdzenie zakupu',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/order/:orderId/redirection',
        name: 'redirection',
        component: () => import('@/views/OrderRedirect.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Przekierowanie do płatności',
            breadcrumb: [
                {
                    text: 'Przekierowanie do płatności',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/powiadomienia',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Powiadomienia',
            breadcrumb: [
                {
                    text: 'Powiadomienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/polityka-prywatnosci',
        name: 'policy-privacy',
        component: () => import('@/views/PolicyPrivacy.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Polityka prywatnosci',
            breadcrumb: [
                {
                    text: 'Polityka prywatnosci',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/rodo',
        name: 'gdpr',
        component: () => import('@/views/GDPR.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'RODO',
            breadcrumb: [
                {
                    text: 'RODO',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/regulamin',
        name: 'regulations',
        component: () => import('@/views/Regulations.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Regulamin',
            breadcrumb: [
                {
                    text: 'Regulamin',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/resetuj-haslo',
        name: 'reset-password',
        component: () => import('@/views/PasswordReset.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/logowanie',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/rejestracja',
        name: 'register',
        component: () => import('@/views/Register.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            layout: 'full',
            uuu: window.location.referer,
        },
    },
    {
        path: '/alochemora-31102024',
        name: 'alochemora',
        component: () => import('@/views/Alochemora.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            layout: 'full',
            pageTitle: 'Alochemora!',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

routesList.push(
    {
        path: '/sklep',
        name: 'shop',
        component: () => import('@/views/shop/Shop.vue'),
        meta: {
            pageTitle: 'Sklep',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
)

const router = new VueRouter({
    mode: 'history',
    base: window.location.pathname.match('/pwa') ? '/pwa' : '/',
    scrollBehavior() {
        return {top: 0}
    },
    routes: routesList,
})

function getQueryParams(url) {
    let params = {};
    let parser = new URL(url);
    let queryString = parser.search.substring(1);
    let queries = queryString.split("&");
    for (let query of queries) {
        let [key, value] = query.split("=");
        params[key] = decodeURIComponent(value || "");
    }
    return params;
}

// Funkcja do usuwania tagów UTM z parametrów URL
function removeUtmParams(url) {
    let parser = new URL(url);
    let params = new URLSearchParams(parser.search);

    // Lista tagów UTM
    let utmTags = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_id"];
    for (let tag of utmTags) {
        params.delete(tag);
    }

    parser.search = params.toString();
    return parser.toString();
}

router.beforeEach((to, _, next) => {

    window.scrollTo(0, 0);
    const isLoggedIn = isUserLoggedIn()

    // let params = getQueryParams(window.location.href);
    //
    // let utmParams = {};
    // let utmTags = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "utm_id"];
    // let foundUtm = false;
    // for (let tag of utmTags) {
    //     if (params[tag]) {
    //         utmParams[tag] = params[tag];
    //         foundUtm = true;
    //     }
    // }
    //
    // if (foundUtm) {
    //     localStorage.setItem("ChemmasterUTM", JSON.stringify(utmParams));
    //
    //    /* let newUrl = removeUtmParams(window.location.href);
    //     window.location.replace(newUrl);*/
    // }

    if (!isLoggedIn && !to.meta.openForGuest) {
        next('logowanie')
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next('/')
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {

    Vue.nextTick(() => {
        document.title = to.meta.pageTitle + " - Chemmaster.pl" || "Chemmaster.pl";
    });
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
